export const menuMobileInit = () => {
  const menuButton = document.querySelector('.header__menu-toggle')
  const menuMobile = document.querySelector('.menu-mobile')
  const header = document.querySelector('.header-wrapper')
  const loginButton = document.querySelector('.login-button')

  const toggleMenu = () => {
    menuMobile.classList.toggle('menu-mobile_active')
    header.classList.toggle('header-wrapper_position')
    menuButton.classList.toggle('header__menu-toggle_active')
    loginButton.classList.toggle('login-button_hidden')
  }

  const toCloseMenu = () => {
    menuMobile.classList.remove('menu-mobile_active')
    header.classList.remove('header-wrapper_position')
    menuButton.classList.remove('header__menu-toggle_active')
    loginButton.classList.remove('login-button_hidden')
  }

  const handleResize = () => {
    const screenWidth = window.screen.width
    if (screenWidth >= 960) {
      toCloseMenu()
    }
  }

  menuButton.addEventListener('click', toggleMenu)
  window.addEventListener('resize', handleResize)
}
