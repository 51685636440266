import { serializeForm } from '../utils'

export const signInToggleInit = () => {
  const toggleAuths = document.querySelectorAll('.auth__toggle, .auth__sign-up-link')
  const signIn = document.querySelector('.auth_sign-in')
  const signUp = document.querySelector('.auth_sign-up')
  const auths = [signIn, signUp]

  const signInToggle = () => {
    for (const auth of auths) {
      if (!auth.classList.contains('auth_active')) {
        auth.classList.add('auth_active')
      } else {
        auth.classList.remove('auth_active')
      }
    }
  }

  if (toggleAuths.length) {
    for (const button of toggleAuths) {
      button.addEventListener('click', signInToggle)
    }
  }
}

const validatePasswordInit = () => {
  const password = document.getElementById('password')
  const confirm_password = document.getElementById('confirm_password')

  function validatePassword() {
    if (password.value != confirm_password.value) {
      confirm_password.setCustomValidity('Пароль не совпал')
    } else {
      confirm_password.setCustomValidity('')
    }
  }
  if (password || confirm_password) {
    password.onchange = validatePassword
    confirm_password.onkeyup = validatePassword
  }
}

export const sendFormInit = () => {
  const forms = document.querySelectorAll('.form_ajax')

  const handleSubmit = async (e) => {
    e.preventDefault()
    const form = e.target;
    const data = serializeForm(form);
    const error_alert = form.querySelector('.form__alert_error');
    console.log(data)
    try {
      const response = await fetch(form.action, {
        method: form.method,
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify(data),
      })

      let result = await response.json();

      if (result.success) {
        location = location.pathname;
      } else {
        console.log(error_alert);
        if (result.error) {
          error_alert.innerText = result.error;
        }
        error_alert.classList.add('form__alert_visible')
      }

      console.log(result)
    } catch (error) {
      error_alert.classList.add('form__alert_visible')
    }
  }

  validatePasswordInit()
  for (const form of forms) {
    form.addEventListener('submit', handleSubmit)
  }
}
