import { Fancybox } from '@fancyapps/ui'
import { menuMobileInit } from './menuMobile/menu-mobile.js'
import { signInToggleInit, sendFormInit } from './modalWindow/modalWindow.js'
import { userProfileMenuInit } from './user-profile/user-profile.js'
import { linkActiveInit } from './user-profile-panel/user-profile-panel.js'
import { doScrollingInit } from './teacher-resume/teacher-resume.js'
import { emailModalWindow } from './emailModalWindow/emailModalWindow.js'

document.addEventListener('DOMContentLoaded', () => {
  menuMobileInit()
  signInToggleInit()
  sendFormInit()
  userProfileMenuInit()
  linkActiveInit()
  doScrollingInit()
  emailModalWindow()
  window.Fancybox = Fancybox
})
