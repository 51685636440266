export const userProfileMenuInit = () => {
  const profile = document.querySelector('.user-profile-title')
  const menu = document.querySelector('.user-profile-menu-mobile')
  const header = document.querySelector('.header-wrapper')

  const toggleMenu = () => {
    menu.classList.toggle('user-profile-menu-mobile_active')
    header.classList.toggle('header-wrapper_position')
  }
  if (profile) {
    profile.addEventListener('click', toggleMenu)
  }
}