export const linkActiveInit = () => {
  const links = document.querySelectorAll('.user-profile-link')

  const changeLink = (e) => {
    for(const link of links) {
        if(link.classList.contains('user-profile-link_active')) {
            link.classList.remove('user-profile-link_active')
        }
    }
    e.currentTarget.classList.add('user-profile-link_active')
  }

  for (const link of links) {
    link.addEventListener('click', changeLink)
  }
}
