//Запрос на почту
import { serializeForm } from '../utils'

export const emailModalWindow = () => {

    // const button = document.querySelector('#free_lesson')
    // console.log(button)
   
    const form = document.querySelector('#mauticform_freelesson')

    const Submit = async (e) => { 
      e.preventDefault(); 
      e.stopImmediatePropagation();

      const strForm = e.target
      const data = serializeForm(strForm)
      const error = strForm.querySelector('.form__alert');

      try {
        const response = await fetch(strForm.action,{    
            method: strForm.method,
            headers: {
            'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        })
          
        const result = await response.json()

        if (result.success) {
          location = location.pathname;
          e.setAttribute("disabled", "disabled");
        } else {
          console.log(error);
          if (result.error) {
            error.innerText = result.error;
          }
          error.classList.add('form__alert_visible')
        }
        
        console.dir(result)
      } catch (error) {
        // console.log(error)
      }
    }
    
  form.addEventListener('submit', Submit)
    
    
}
